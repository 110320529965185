import React, { useEffect, useMemo } from 'react';
import DOMPurify from 'dompurify';
import classNames from 'classnames';
import { marked } from 'marked';

/* import styles from './Faq.module.scss'; */

type HTMLProps = {
  htmlString: string;
  className?: string;
  tag?: string;
};

const HTMLRenderer: React.FC<HTMLProps> = ({ htmlString, tag = 'div' }) => {
  const sanitizedHTMLString = DOMPurify.sanitize(htmlString);

  return React.createElement(tag, {
    dangerouslySetInnerHTML: { __html: sanitizedHTMLString },
  });
};

type MarkdownProps = {
  markdownString: string;
  className?: string;
  inline?: boolean;
  tag?: string;
};

const renderer = {
  link(href: string, title: string, text: string) {
    const externalLink = /^(https?|www\.|\/\/)/.test(href || '');
    const targetAttr = externalLink ? 'target="_blank"' : undefined;
    const relAttr = externalLink ? 'rel="noopener"' : undefined;
    const titleAttr = title ? `title="${title}"` : undefined;
    const attributes = [targetAttr, relAttr, titleAttr].filter(Boolean);

    return `<a href="${href}" ${attributes.join(' ')}>${text}</a>`;
  },
};

marked.use({ renderer });

const MarkdownComponent: React.FC<MarkdownProps> = ({ markdownString, className, tag = 'div', inline = false }) => {
  const sanitizedHTMLString = useMemo(() => {
    const parseDelegate = inline ? marked.parseInline : marked.parse;
    const dirtyHTMLString = parseDelegate(markdownString);

    return DOMPurify.sanitize(dirtyHTMLString, { ADD_ATTR: ['target'] });
  }, [inline, markdownString]);

  return React.createElement(tag, { dangerouslySetInnerHTML: { __html: sanitizedHTMLString }, className: classNames(className) });
};

const Faq: React.FC = () => {
  useEffect(() => {
    const accordionItems = document.querySelectorAll('.accordion-item');

    accordionItems.forEach(item => {
      const title = item.querySelector('.accordion-title');
      title?.addEventListener('click', () => {
        item.classList.toggle('active');
        const content = item.querySelector('.accordion-content') as HTMLElement;

        if (item.classList.contains('active')) {
          content.style.display = 'block';
        } else {
          content.style.display = 'none';
        }
      });
    });
  }, []);

  return (

    <div className="accordion-container" style={{ backgroundColor: '#1a1a1a', padding: '30px', borderRadius: '8px', textAlign: 'justify', margin: '20px' }}>
      <div style={{ textAlign: 'center' }}>
        <img
          src="http://tcsahora-1.s3.us-east-2.amazonaws.com/wp-content/uploads/2024/09/02095538/tvOS_App-Icon-Image_1280x768-1.png"
          alt="TCS GO Logo"
          style={{

            margin: '0 auto 20px auto',
            maxWidth: '200px',
            height: 'auto',
            // Puedes ajustar este valor según el diseño que desees
          }}
        />
      </div>

      <div className="accordion-item" style={{ borderBottom: "1px solid #ccc", marginBottom: '7px' }}>
        <div className="accordion-title" style={{ cursor: 'pointer', fontSize: '25px', marginBottom: '12px' }}> 1. No recibí el Token de actualización de contraseña. ¿Qué hago?</div>
        <div className="accordion-content" style={{ display: 'none', padding: '15px', backgroundColor: '#333' }}>
          <p>Verifica que el correo con el que estás solicitando el cambio de contraseña corresponde a la cuenta con la que
            has estado suscrito a TCS GO!</p>
          <p>Si el correo efectivamente corresponde a tu cuenta activa TCS GO!, comunícate con nuestro equipo de soporte al
            <a href="https://wa.me/50369383613" style={{ color: '#00aaff' }}>https://wa.me/50369383613</a> para solicitar un cambio de contraseña
            manual.</p>
        </div>
      </div>

      <div className="accordion-item" style={{ borderBottom: "1px solid #ccc", marginBottom: '7px' }}>
        <div className="accordion-title" style={{ cursor: 'pointer', fontSize: '25px', marginBottom: '12px' }}>2. Pagué mi suscripción y no se activó el servicio.</div>
        <div className="accordion-content" style={{ display: 'none', padding: '15px', backgroundColor: '#333' }}>
          <p>Comprueba que tienes una suscripción activa de acuerdo con la plataforma en la que te hayas suscrito:</p>
          <ul>
            <li>Apple ID: Apartado “Apple ID” en Configuración / “Suscripciones” / Elije la aplicación TCS Go! para comprobar
              su estado.</li>
            <li>Google Play Store: Apartado “Pagos y Suscripciones” / “Suscripciones” / Elije la aplicación TCS Go! para
              “Administrar suscripción” y comprobar su estado.</li>
            <li>Tcsgo.com: inicia sesión, presiona el ícono de usuario al extremo derecho de la pantalla, y dirígete de
              “Pagos” ahí podrás comprobar si tienes una suscripción activa, y verificar los últimos 4 dígitos de la
              tarjeta con la que tienes o no activo el servicio.</li>
          </ul>
        </div>
      </div>
      <div className="accordion-item" style={{ borderBottom: "1px solid #ccc", marginBottom: '7px' }}>
        <div className="accordion-title" style={{ cursor: 'pointer', fontSize: '25px', marginBottom: '12px' }}>3. ¿Cuáles son las formas de pago para suscribirme a TCS GO?</div>
        <div className="accordion-content" style={{ display: 'none', padding: '15px', backgroundColor: '#333' }}>
          <p>Las vías oficiales para suscripción son:</p>
          <ul>
            <li>Directamente entrando en tu navegador a <a href="http://www.tcsgo.com/" style={{ color: '#00aaff' }}>www.tcsgo.com</a></li>
            <li>Descargando la App TCS GO” a través de tu dispositivo iOS, TVOS, Android o Android TV.</li>
          </ul>
          <p>Métodos de pago:</p>
          <ul>
            <li>Tarjetas de crédito o débito asociados a tus dispositivos.</li>
            <li>Tarjetas de crédito o débito en <a href="http://www.tcsgo.com/" style={{ color: '#00aaff' }}>www.tcsgo.com</a></li>
          </ul>
          <p>A partir de agosto 2024, comprobarás tu pago al recibir un correo electrónico con los detalles de la suscripción.</p>
        </div>
      </div>
      <div className="accordion-item" style={{ borderBottom: "1px solid #ccc", marginBottom: '7px' }}>
        <div className="accordion-title" style={{ cursor: 'pointer', fontSize: '25px', marginBottom: '12px' }}>4. ¿Cómo puedo cancelar mi suscripción?</div>
        <div className="accordion-content" style={{ display: 'none', padding: '15px', backgroundColor: '#333' }}>
          <ul>
            <li>iOS: apartado “Apple ID” en Configuración / “Suscripciones”/ Elije la aplicación TCS GO! / Cancelar suscripción.</li>
            <li>Android en Play Store: apartado “Pagos y Suscripciones” / “Suscripciones” / Elije la aplicación TCS GO! para
              “Administrar suscripción” / Cancelar suscripción.</li>
            <li>Y en tcsgo.com: inicia sesión, presiona el ícono de usuario al extremo derecho de la pantalla, y dirígete a
              “Pagos”. Cancela tu suscripción. Si no puedes realizarlo por esa vía, solicita a nuestro equipo de soporte
              la cancelación de tu cuenta, indicando tus datos: correo electrónico con el que tienes la suscripción,
              Nombre completo, últimos 4 dígitos de tu tarjeta.</li>
          </ul>
        </div>
      </div>
      <div className="accordion-item" style={{ borderBottom: "1px solid #ccc", marginBottom: '7px' }}>
        <div className="accordion-title" style={{ cursor: 'pointer', fontSize: '25px', marginBottom: '12px' }}>5. ¿Cómo iniciar sesión en Smart TV? (TUTORIAL)</div>
        <div className="accordion-content" style={{ display: 'none', padding: '15px', backgroundColor: '#333' }}>
          <ul>
            <li><strong>Apple TV:</strong> Ve a App Store, descarga la aplicación y ábrela, inicia sesión y la plataforma te
              solicitará escanear un código QR con tu celular que te llevará a una pantalla donde deberás ingresar tus
              credenciales.</li>
            <li><strong>Android TV:</strong> Ve a Google Play Store, descarga la aplicación y ábrela, inicia sesión y la
              plataforma te solicitará escanear un código QR con tu celular que te llevará a una pantalla donde deberás
              ingresar tus credenciales.</li>
          </ul>
        </div>
      </div>
      <div className="accordion-item" style={{ borderBottom: "1px solid #ccc", marginBottom: '7px' }}>
        <div className="accordion-title" style={{ cursor: 'pointer', fontSize: '25px', marginBottom: '12px' }}>6. ¿Qué puedo ver en vivo en TCS GO?</div>
        <div className="accordion-content" style={{ display: 'none', padding: '15px', backgroundColor: '#333' , overflow:'hidden'}}>
          <p>La programación de TCS GO varía según el país en el que te encuentres, por lo que tenemos 3 categorías de programación:</p>
          <ul>
            <li><strong>A. El Salvador:</strong> Visita: <a href="https://www.tcsahora.com/programacion-tv-tcs/" style={{ color: '#00aaff' }}>https://www.tcsahora.com/programacion-tv-tcs/</a></li>
            <li><strong>B. Estados Unidos:</strong> Navega el sitio <a href="https://www.tcsgo.com/" style={{ color: '#00aaff' }}>https://www.tcsgo.com/</a> para conocer los contenidos disponibles en tu país.</li>
            <li><strong>C. Resto del Mundo:</strong> Navega el sitio <a href="https://www.tcsgo.com/" style={{ color: '#00aaff' }}>https://www.tcsgo.com/</a> para conocer los contenidos disponibles en tu país.</li>
          </ul>
        </div>
      </div>
      <div className="accordion-item" style={{ borderBottom: "1px solid #ccc", marginBottom: '7px' }}>
        <div className="accordion-title" style={{ cursor: 'pointer', fontSize: '25px', marginBottom: '12px' }}>7. ¿Dónde puedo conocer la programación Deportiva de TCS GO?</div>
        <div className="accordion-content" style={{ display: 'none', padding: '15px', backgroundColor: '#333' , overflow:'hidden'}}>
          <p>Para El Salvador: <a href="https://www.tcsahora.com/programacion-tv-tcs/" style={{ color: '#00aaff' }}>https://www.tcsahora.com/programacion-tv-tcs/</a></p>
          <p>Para USA y resto del mundo: Visita nuestras redes sociales y tendrás información actualizada de los eventos deportivos a transmitirse en TCS GO USA.</p>
        </div>
      </div>
      <div className="accordion-item" style={{ borderBottom: "1px solid #ccc", marginBottom: '7px' }}>
        <div className="accordion-title" style={{ cursor: 'pointer', fontSize: '25px', marginBottom: '12px' }}>8. No puedo ver un contenido.</div>
        <div className="accordion-content" style={{ display: 'none', padding: '15px', backgroundColor: '#333' }}>
          <p>Repórtalo a nuestro Equipo de Soporte <a href="https://wa.me/5036938361" style={{ color: '#00aaff' }}>https://wa.me/5036938361</a> para recibir asistencia.</p>
        </div>
      </div>
      <div className="accordion-item" style={{ borderBottom: "1px solid #ccc", marginBottom: '7px' }}>
        <div className="accordion-title" style={{ cursor: 'pointer', fontSize: '25px', marginBottom: '12px' }}>9. Consulta no relacionada al servicio TCS GO!</div>
        <div className="accordion-content" style={{ display: 'none', padding: '15px', backgroundColor: '#333' }}>
          <p>Si tienes alguna consulta no relacionada al servicio TCS GO! pero sí a Telecorporación Salvadoreña, puedes enviar un correo a: <a href="mailto:info.mercadeo@tcs246.com" style={{ color: '#00aaff' }}>info.mercadeo@tcs246.com</a></p>
        </div>
      </div>
    </div>
  );
};

export { MarkdownComponent, HTMLRenderer };
export default Faq;
