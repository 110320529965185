import React, { useMemo } from 'react';
import DOMPurify from 'dompurify';
import classNames from 'classnames';
import { marked } from 'marked';

/* import styles from './MarkdownAndHTMLComponent.module.scss'; */

type HTMLProps = {
  htmlString: string;
  className?: string;
  tag?: string;
};

const HTMLRenderer: React.FC<HTMLProps> = ({ htmlString,  tag = 'div' }) => {
  const sanitizedHTMLString = DOMPurify.sanitize(htmlString);

  return React.createElement(tag, { 
    dangerouslySetInnerHTML: { __html: sanitizedHTMLString }
  });
};

type MarkdownProps = {
  markdownString: string;
  className?: string;
  inline?: boolean;
  tag?: string;
};

const renderer = {
  link(href: string, title: string, text: string) {
    const externalLink = /^(https?|www\.|\/\/)/.test(href || '');
    const targetAttr = externalLink ? 'target="_blank"' : undefined;
    const relAttr = externalLink ? 'rel="noopener"' : undefined;
    const titleAttr = title ? `title="${title}"` : undefined;
    const attributes = [targetAttr, relAttr, titleAttr].filter(Boolean);

    return `<a href="${href}" ${attributes.join(' ')}>${text}</a>`;
  },
};

marked.use({ renderer });

const MarkdownComponent: React.FC<MarkdownProps> = ({ markdownString, className, tag = 'div', inline = false }) => {
  const sanitizedHTMLString = useMemo(() => {
    const parseDelegate = inline ? marked.parseInline : marked.parse;
    const dirtyHTMLString = parseDelegate(markdownString);

    return DOMPurify.sanitize(dirtyHTMLString, { ADD_ATTR: ['target'] });
  }, [inline, markdownString]);

  return React.createElement(tag, { dangerouslySetInnerHTML: { __html: sanitizedHTMLString }, className: classNames( className) });
};

const Terminos = () => {
  const htmlContent = `
    <div style="color: white !important; margin:30px !important;">
    <p style="text-align:center;"><strong>T&Eacute;RMINOS DE USO</strong></p>
<p style="text-align:center;">________________________________________</p>
<p style="text-align:center;"><strong>CONTRATO DE SUSCRIPCI&Oacute;N Y USO DE SOFTWARE</strong></p>
<p style="text-align:center;">&Uacute;ltima fecha de actualizaci&oacute;n: 1 de Noviembre de 2022.</p>
<p style="text-align:justify;">AL USAR TCS GO Y/O CREAR UNA CUENTA EN TCS GO, USTED (EL &ldquo;USUARIO&rdquo; O
    &ldquo;USTED&rdquo;), EN SU CALIDAD DE USUARIO FINAL DE TCS GO Y DEL SOFTWARE, ACEPTA INCONDICIONALMENTE LOS
    T&Eacute;RMINOS Y CONDICIONES DE ESTE CONTRATO, INCLUYENDO SUS T&Eacute;RMINOS DE USO Y EL AVISO DE PRIVACIDAD, Y
    ACEPTA QUE USTED QUEDA OBLIGADO Y SE CONVIERTE EN UNA DE LAS PARTES DE ESTE CONTRATO (EL &quot;CONTRATO&quot;) CON
    CANAL DOS, S.A. DE C.V., QUE PARA EFECTOS DE ESTE DOCUMENTO PODR&Aacute; DENOMINARSE CANAL DOS, S.A. DE C.V. O
    SIMPLEMENTE TCS.</p>
<p style="text-align:justify;">SI USTED NO EST&Aacute; DE ACUERDO CON TODOS O ALGUNOS DE LOS T&Eacute;RMINOS Y
    CONDICIONES DE ESTE CONTRATO Y/O DEL AVISO DE PRIVACIDAD, NO UTILICE TCS GO Y/O NO CONTIN&Uacute;E CON EL PROCESO DE
    CONTRATACI&Oacute;N DE TCS GO. SI USTED YA ES SUSCRIPTOR DE TCS GO Y NO EST&Aacute; DE ACUERDO CON TODOS O CON
    ALGUNO DE LOS T&Eacute;RMINOS Y CONDICIONES DE ESTE CONTRATO Y/O DEL AVISO DE PRIVACIDAD, POR FAVOR SIGA LAS
    INSTRUCCIONES QUE SE ESTABLECEN EN ESTE CONTRATO PARA CANCELAR SU CUENTA EN TCS GO.</p>
<p style="text-align:center;">________________________________________</p>
<p style="text-align:center;"><strong>1. DEFINICIONES</strong></p>
<p style="text-align:justify;">Para efectos del presente Contrato y sin perjuicio de los t&eacute;rminos definidos a lo
    largo del mismo, los t&eacute;rminos que se definen a continuaci&oacute;n tendr&aacute;n los significados que a
    continuaci&oacute;n se les atribuye:</p>
<p style="text-align:justify;">&quot;Agentes&quot;: Son las personas morales que podr&aacute;n realizar diversas
    actividades, incluyendo la promoci&oacute;n y/o comercializaci&oacute;n de TCS GO por cualquier medio, env&iacute;o
    de estados de cuenta de la Suscripci&oacute;n Mensual, as&iacute; como el cobro a nombre y por cuenta de TCS de la
    Suscripci&oacute;n Mensual. Los Agentes podr&aacute;n cambiar de tiempo en tiempo sin previo aviso. Los Agentes no
    adquieren responsabilidad alguna respecto a la suscripci&oacute;n a TCS GO contratado entre Usted y CANAL DOS, S.A.
    DE C.V., y no forman parte del presente Contrato. Los Agentes pueden cambiar de un pa&iacute;s a otro pa&iacute;s.
</p>
<p style="text-align:justify;"><strong>&quot;TCS GO&quot;:</strong> Es la suscripci&oacute;n bajo demanda (Subscription
    Video on Demand o SVOD por sus siglas en ingl&eacute;s), ofrecida en l&iacute;nea v&iacute;a remota por CANAL DOS,
    S.A. DE C.V., mediante una conexi&oacute;n v&iacute;a internet a trav&eacute;s del Sitio y/o App, que ofrece acceso
    a los Programas para su uso estrictamente personal, a cambio del pago de la Suscripci&oacute;n Mensual.</p>
<p style="text-align:justify;"><strong>&quot;Cuenta TCS GO&quot;:</strong> Es la cuenta del Usuario con la que al ser
    autenticado se podr&aacute; tener acceso a TCS GO, misma que puede ser creada por el Usuario dentro del Sitio, o
    bien, generada mediante otro tipo de mecanismo de registro que determine TCS GO.</p>
<p style="text-align:justify;"><strong>&quot;Dispositivos&quot;:</strong> Son los equipos electr&oacute;nicos propiedad
    del Usuario mediante los cuales el Usuario podr&aacute; tener acceso a TCS GO ya sea a trav&eacute;s del Sitio y/o
    App de TCS GO, siempre y cuando dicho equipo (i) tenga la funcionalidad o posibilidad de conectarse directa o
    indirectamente a internet, y (ii) sea soportado tecnol&oacute;gicamente por la plataforma de TCS GO.</p>
<p style="text-align:justify;">&quot;<strong>Forma de Pago&quot;:&nbsp;</strong>Es el medio de pago que Usted elija de
    entre los medios aceptados por CANAL DOS, S.A. DE C.V., y vigentes en ese momento, tales como cargo recurrente a
    tarjeta de cr&eacute;dito o d&eacute;bito vigente, agregadores de pago (como PayPal), pago a trav&eacute;s de
    cualquiera de los Agentes de CANAL DOS, S.A. DE C.V., que se encuentren disponibles en TCS GO como lo son operadores
    de televisi&oacute;n de paga, sistemas de prepago como c&oacute;digos digitales de prepago y tarjetas de regalo. Por
    favor considere que las Formas de Pago pueden cambiar de un pa&iacute;s a otro.</p>
<p style="text-align:justify;"><strong>&quot;Licencia de Uso&quot;:</strong> Es la licencia de uso respecto del Software
    que CANAL DOS, S.A. DE C.V. otorga al Usuario en t&eacute;rminos del presente Contrato.</p>
<p style="text-align:justify;"><strong>&quot;TCS&quot;:</strong> Significa CANAL DOS, S.A. de C.V., sociedad de
    nacionalidad salvadore&ntilde;a, con domicilio en la ciudad de San Salvador, quien es el &uacute;nico responsable y
    obligado a proporcionar el acceso a TCS GO y al otorgamiento de la Licencia de Uso de Software.</p>
<p style="text-align:justify;"><strong>&quot;Programas&quot;:</strong> Son las obras audiovisuales, incluyendo sin
    limitar pel&iacute;culas, series, programas, documentales y/o cualquier tipo de contenido audiovisual o de audio a
    los que podr&aacute; tener acceso el Usuario a trav&eacute;s de TCS GO, y en caso de ser aplicable seg&uacute;n el
    territorio geogr&aacute;fico en que Usted se encuentre y en que Usted haya contratado TCS GO, se podr&aacute;
    incluir el contenido de ciertas se&ntilde;ales lineales que TCS determine. Por favor considere que aun cuando TCS GO
    est&aacute; disponible en varios pa&iacute;ses del mundo, ciertos Programas pueden ser bloqueados para que no puedan
    ser visualizados en ciertos pa&iacute;ses.</p>
<p style="text-align:justify;"><strong>&quot;Sitio y/o App&quot;:</strong> Es la p&aacute;gina web ubicada en el URL
    www.tcsgo.com, y/o su correspondiente aplicaci&oacute;n m&oacute;vil.</p>
<p style="text-align:justify;"><strong>&quot;Software&quot;:</strong> Es el sistema de c&oacute;mputo (incluyendo
    aplicaciones m&oacute;viles) utilizado para la transmisi&oacute;n de TCS GO y sus Programas desarrollado por TCS o
    por terceros, as&iacute; como las interfaces del Usuario correspondientes que se dise&ntilde;aron para permitir el
    acceso a TCS GO y que permiten la visualizaci&oacute;n de sus Programas en el Sitio y/o App a trav&eacute;s de los
    Dispositivos.</p>
<p style="text-align:justify;"><strong>&quot;Suscripci&oacute;n Mensual&quot;:</strong> Significa el cargo que se le
    cobrar&aacute; al Usuario de manera anticipada para tener acceso v&iacute;a internet a TCS GO de manera mensual,
    mismo que puede ser mediante un cargo mensual, recurrente, o bien, a trav&eacute;s de medios de sistemas de prepago.
    Por favor considere que aun cuando TCS GO est&aacute; disponible en varios pa&iacute;ses del mundo, el monto de
    dinero y la moneda aplicable para la Suscripci&oacute;n Mensual puede cambiar de un pa&iacute;s a otro pa&iacute;s
    (aunque el monto y la moneda de la Suscripci&oacute;n Mensual seguir&aacute; siendo el mismo para Usted seg&uacute;n
    el pa&iacute;s en que haya contratado TCS GO).</p>
<p style="text-align:justify;"><strong>&quot;T&eacute;rminos de Uso&quot;:</strong> Son las condiciones de otorgamiento
    de la Licencia de Uso del Software que utilizar&aacute; para visualizar los Programas, as&iacute; como todos los
    t&eacute;rminos y condiciones de uso del Software y de TCS GO que se se&ntilde;ala en el presente Contrato.</p>
<p style="text-align:center;">________________________________________</p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:center;"><strong>2. DESCRIPCI&Oacute;N GENERAL DE TCS GO</strong></p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:justify;">TCS GO consiste en otorgar a Usted acceso v&iacute;a internet a los Programas para su uso
    estrictamente personal a cambio del pago de la Suscripci&oacute;n Mensual o Anual.</p>
<p style="text-align:justify;">Para disfrutar de TCS GO, Usted deber&aacute; contar con acceso a internet en
    alg&uacute;n Dispositivo. Recomendamos ampliamente utilizar Dispositivos que soporten TCS GO y contar con una
    conexi&oacute;n a internet de al menos 2 Mbps (2 MB).</p>
<p style="text-align:justify;">Aun cuando TCS GO est&aacute; disponible en varios pa&iacute;ses del mundo, por favor
    considere que (i) las Formas de Pago pueden cambiar de una pa&iacute;s a otro pa&iacute;s; (ii) el contenido de las
    se&ntilde;ales lineales no son ofrecidos en todos los pa&iacute;ses en donde opera TCS GO; (iii) ciertos Programas
    pueden ser bloqueados para que no puedan ser visualizados en ciertos pa&iacute;ses; (iv) el monto de dinero y la
    moneda aplicable para la Suscripci&oacute;n Mensual puede cambiar de pa&iacute;s a pa&iacute;s, aunque el monto y la
    moneda de la Suscripci&oacute;n Mensual seguir&aacute; siendo el mismo para Usted seg&uacute;n el pa&iacute;s en que
    haya contratado TCS GO; (v) los Agentes pueden cambiar de un pa&iacute;s a otro pa&iacute;s. Para m&aacute;s
    informaci&oacute;n le agradeceremos que se comunique al centro de servicio al cliente (ver Secci&oacute;n 13
    siguiente).</p>
<p style="text-align:justify;">Sujeto a que TCS cuente con los derechos necesarios, TCS GO podr&aacute; incluir, a
    discreci&oacute;n absoluta de TCS y sin que se entienda como una obligaci&oacute;n de su parte, el acceso al
    contenido de ciertas se&ntilde;ales lineales. El contenido y/o se&ntilde;ales lineales ser&aacute;n definidos por
    TCS de tiempo en tiempo y podr&aacute;n ser modificados, cambiados o eliminados a discreci&oacute;n absoluta de TCS
    en cualquier momento y sin necesidad de autorizaci&oacute;n o aviso previo.</p>
<p style="text-align:justify;">La fabricaci&oacute;n y venta de los Dispositivos est&aacute; a cargo de otras entidades
    distintas a TCS, por lo que Usted deber&aacute; contar con los Dispositivos y software necesarios para conectarse a
    internet y utilizar TCS GO.</p>
<p style="text-align:justify;">TCS podr&aacute; llevar a cabo ajustes a TCS GO con la frecuencia que determine de manera
    unilateral.</p>
<p style="text-align:center;"><strong>________________________________________</strong></p>
<p style="text-align:center;"><strong>3. ACEPTACI&Oacute;N DEL CONTRATO Y DE LOS T&Eacute;RMINOS DE USO</strong></p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:justify;">El presente Contrato, incluyendo los T&eacute;rminos de Uso, rigen el uso que Usted haga
    de TCS GO y del Software. Al utilizar TCS GO o bien, visitar o navegar en el Sitio y/o App, Usted acuerda y se
    compromete a cumplir el presente Contrato. Si Usted no est&aacute; de acuerdo con el presente Contrato, no
    deber&aacute; utilizar TCS GO ni el Software.</p>
<p style="text-align:justify;">Por favor tome en cuenta que el presente Contrato, incluyendo los T&eacute;rminos de Uso,
    aplica para el uso que Usted haga de TCS GO a trav&eacute;s Sitio y/o App de los Dispositivos mediante los cuales
    tiene acceso a TCS GO; sin embargo, es posible que, si Usted desea consultar el presente Contrato y los
    T&eacute;rminos de Uso, as&iacute; como nuestro Aviso de Privacidad, tenga que hacerlo directamente en nuestro
    Sitio.</p>
<p style="text-align:center;"><strong>________________________________________</strong></p>
<p style="text-align:center;"><strong>4. MODIFICACI&Oacute;N Y CESI&Oacute;N DEL CONTRATO</strong></p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:justify;">TCS se reserva el derecho de modificar y/o ceder una parte o la totalidad del presente
    Contrato incluyendo sus T&eacute;rminos de Uso, a su entera discreci&oacute;n y sin necesidad de aviso o
    notificaci&oacute;n al Usuario ni de autorizaci&oacute;n por parte del Usuario. La &uacute;ltima versi&oacute;n del
    Contrato y del Aviso de Privacidad prevalecer&aacute; sobre todas las versiones publicadas con anterioridad y se
    puede consultar visitando el Sitio y haciendo clic en &quot;T&eacute;rminos de Uso&quot; y en &ldquo;Aviso de
    Privacidad&rdquo;, seg&uacute;n corresponda.</p>
<p style="text-align:justify;">TCS se reserva el derecho de modificar parcial o totalmente la forma en la que funciona
    TCS GO a su entera discreci&oacute;n y sin previo aviso al Usuario.</p>
<p style="text-align:center;"><strong>________________________________________</strong></p>
<p style="text-align:center;"><strong>5. SUSCRIPCI&Oacute;N MENSUAL O ANUAL, COBRO Y FACTURACI&Oacute;N</strong></p>
<p style="text-align:justify;"><strong>1. &nbsp;</strong>TCS o el Agente que corresponda empezar&aacute; a cobrar el
    cargo de su Suscripci&oacute;n Mensual o Anual al completarse el proceso de pago. El Usuario suscriptor de TCS GO
    deber&aacute; cancelar su Suscripci&oacute;n Mensual en caso de que ya no desee contar con el acceso a TCS GO. Para
    visualizar los detalles espec&iacute;ficos de su Cuenta TCS GO, incluido el precio de la Suscripci&oacute;n Mensual,
    visite el Sitio y/o App y haga clic en &quot;Mi Cuenta&quot;.</p>
<p style="text-align:justify;">Si Usted desea cancelar su Cuenta TCS GO, por favor haga clic en &quot;Mi Cuenta&quot; en
    el Sitio y siga las instrucciones que ah&iacute; se establecen (ver Secci&oacute;n 13 siguiente).</p>
<p style="text-align:justify;">Usted podr&aacute; cancelar su Cuenta TCS GO en cualquier momento; sin embargo, no se
    realizar&aacute;n reembolsos ni cr&eacute;ditos por los periodos no utilizados. En caso de cancelaci&oacute;n, TCS
    GO dejar&aacute; de estar disponible para el Usuario el &uacute;ltimo d&iacute;a del mes en que haya cobrado su
    Suscripci&oacute;n Mensual o Anual.</p>
<p style="text-align:justify;">TCS puede ofrecer diversos planes de Suscripci&oacute;n Mensual o Anual, incluidos los
    planes de promociones especiales o las suscripciones con distintas limitaciones. Algunas de estas suscripciones
    promocionales pueden ser ofrecidas a trav&eacute;s de terceros junto con la provisi&oacute;n de sus propios
    productos y servicios. TCS no es ni ser&aacute; responsable por los productos y servicios provistos por dichos
    terceros. TCS se reserva el derecho a terminar, rescindir o modificar de cualquier otra forma cualquiera de los
    planes con los que se ofrece TCS GO.</p>
<p style="text-align:justify;"><strong>2.</strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>Pago, cobranza
        y facturaci&oacute;n:&nbsp;</strong>Al iniciar su suscripci&oacute;n a TCS GO, Usted acuerda expresamente que
    TCS, sus Agentes, los agregadores de pagos o cualquier otro sistema o medio de pagos autorizado se encuentran
    facultados para cobrarle la Suscripci&oacute;n Mensual o Anual a la tarifa vigente en ese momento seg&uacute;n el
    pa&iacute;s en que haya realizado la suscripci&oacute;n a TCS GO, a trav&eacute;s de la Forma de Pago que Usted
    registr&oacute;, o bien, mediante la Forma de Pago que Usted indique al modificar la informaci&oacute;n de su Cuenta
    TCS GO.</p>
<p style="text-align:justify;">Actualmente, dependiendo el pa&iacute;s en que se encuentre el Usuario, las Formas de
    Pago aceptadas en TCS GO pueden ser las siguientes:</p>
<p style="text-align:justify;">a.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Tarjetas de cr&eacute;dito.</p>
<p style="text-align:justify;">b.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Tarjetas de d&eacute;bito.</p>
<p style="text-align:justify;">d.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Cargo autom&aacute;tico al estado de
    cuenta mensual de los servicios prestados por el Agente u operador de televisi&oacute;n de paga que corresponda.</p>
<p style="text-align:justify;">La cuota de la Suscripci&oacute;n Mensual o Anual se cobrar&aacute; de manera anticipada
    desde el primer d&iacute;a de contrataci&oacute;n de TCS GO y continuar&aacute; hasta que Usted cancele su
    Suscripci&oacute;n Mensual y haya liquidado cualquier adeudo. Usted reconoce que al momento de aceptar TCS GO, Usted
    acepta pagar y que TCS o el Agente que corresponda le cobre la Suscripci&oacute;n Mensual o Anual,
    independientemente de que Usted no haga uso de TCS GO, o bien, que Usted vea, escuche o manipule la totalidad o una
    parte de los Programas.</p>
<p style="text-align:justify;">USTED ACEPTA QUE LOS PAGOS NO SON REEMBOLSABLES, POR LO QUE RECONOCE QUE NO SE
    OTORGAR&Aacute;N REEMBOLSOS NI CR&Eacute;DITOS POR PERIODOS NO UTILIZADOS O UTILIZADOS PARCIALMENTE, NI POR
    PROGRAMAS NO VISUALIZADOS O VISUALIZADOS DE MANERA PARCIAL.</p>
<p style="text-align:justify;">Los precios vigentes de la Suscripci&oacute;n Mensual o Anual podr&aacute;n ser
    modificados en cualquier momento por TCS, o bien, se podr&aacute;n agregar nuevos precios de tiempo en tiempo a
    discreci&oacute;n de TCS. Si desea utilizar otra Forma de Pago o si hay alguna modificaci&oacute;n en la Forma de
    Pago, por ejemplo, respecto de la fecha de vencimiento o la validez de su tarjeta de cr&eacute;dito o d&eacute;bito,
    deber&aacute; modificar la informaci&oacute;n correspondiente a la Forma de Pago visitando el Sitio y haciendo clic
    en el v&iacute;nculo &quot;Mi Cuenta&quot;. Asimismo, Usted reconoce que TCS podr&aacute; suspender su acceso a los
    Programas en caso de que su Forma de Pago no sea autorizada por TCS o el Agente que corresponda.</p>
<p style="text-align:center;"><strong>________________________________________</strong></p>
<p style="text-align:center;"><strong>6. CARACTER&Iacute;STICAS Y FUNCIONALIDADES DE TCS GO</strong></p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:justify;"><strong>1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Disponibilidad.</strong> La
    disponibilidad de Programas de TCS GO se modificar&aacute; peri&oacute;dicamente a discreci&oacute;n de TCS. La
    calidad de la imagen de los Programas transmitidos por internet puede variar seg&uacute;n el Dispositivo en el que
    se visualizar&aacute;, la ubicaci&oacute;n del Usuario, el ancho de banda disponible o la velocidad de su
    conexi&oacute;n a internet, por lo que Usted reconoce que TCS no es responsable de ello.</p>
<p style="text-align:justify;">Una peque&ntilde;a cantidad de datos es transmitida como b&uacute;fer cada vez que inicia
    un Programa, por lo que al iniciar o detener los Programas con frecuencia se puede provocar un peque&ntilde;o
    incremento en la cantidad de datos que se transmiten por minuto o por hora.</p>
<p style="text-align:justify;">Todos los cargos de acceso a internet correr&aacute;n por cuenta de Usted. TCS no declara
    ni otorga garant&iacute;a alguna respecto de la calidad de la experiencia que pueda tener el Usuario de ver al
    instante alg&uacute;n Programa en su Dispositivo.</p>
<p style="text-align:justify;"><strong>2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Tiempo de inicio.</strong> El
    tiempo de inicio de visualizaci&oacute;n de un Programa al instante variar&aacute; seg&uacute;n diversos factores,
    incluidos de forma enunciativa el lugar donde el Usuario se encuentra, el ancho de banda disponible en ese momento,
    el Programa que seleccione y la configuraci&oacute;n de su Dispositivo.</p>
<p style="text-align:justify;"><strong>3.</strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>Requisitos y
        limitaciones para Dispositivos.</strong> USTED PUEDE ACCEDER A TCS GO CON UNA MISMA CUENTA TCS GO EN UN
    M&Aacute;XIMO DE CINCO DISPOSITIVOS, PERO S&Oacute;LO HASTA DOS DISPOSITIVOS DE MANERA SIMULT&Aacute;NEA. TCS se
    reserva el derecho de modificar sin previo aviso y en cualquier momento la cantidad de Dispositivos y transmisiones
    simult&aacute;neas a las que el Usuario tiene derecho. Si Usted no logra ver alg&uacute;n Programa, considere que es
    posible que su Dispositivo no cumpla con los requisitos de sistema con los que opera TCS GO.</p>
<p style="text-align:justify;">TCS se reserva el derecho para, sin previo aviso al Usuario y en cualquier momento, (i)
    aumentar o disminuir la cantidad o tipo de Dispositivos mediante los cuales se puede tener acceso a TCS GO, y (ii)
    prohibir o inhabilitar ciertos Programas para que puedan ser o no ser visualizados a trav&eacute;s de determinados
    Dispositivos.</p>
<p style="text-align:justify;"><strong>4.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Limitaci&oacute;n
        geogr&aacute;fica.</strong> Usted puede ver un Programa seg&uacute;n las restricciones que apliquen para el
    territorio geogr&aacute;fico en que Usted se encuentre en ese momento. Las ubicaciones geogr&aacute;ficas en las que
    se ofrece TCS GO podr&aacute;n ser modificadas de tiempo en tiempo. Los Programas que est&aacute;n disponibles para
    su visualizaci&oacute;n pueden variar seg&uacute;n la ubicaci&oacute;n geogr&aacute;fica. Se utilizar&aacute;n
    tecnolog&iacute;as para verificar la ubicaci&oacute;n geogr&aacute;fica donde el Usuario se encuentre y se
    bloquear&aacute; el acceso a TCS GO y/o a ciertos Programas en caso de que se encuentre fuera de la ubicaci&oacute;n
    geogr&aacute;fica permitida.</p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:justify;"><strong>5.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Contrase&ntilde;as.</strong>
    Usted, en calidad de creador, titular y/o usuario de su Cuenta TCS GO y a quien TCS o el Agente que corresponda le
    cobrar&aacute; los cargos a trav&eacute;s de la Forma de Pago, es el titular de la Cuenta TCS GO y tendr&aacute;
    acceso y control sobre la misma. El control del titular de la Cuenta TCS GO se ejerce mediante el uso de la
    contrase&ntilde;a, por lo tanto, para mantener el control exclusivo, el titular de la Cuenta TCS GO no deber&aacute;
    revelar su contrase&ntilde;a ni los datos de su Forma de Pago. AL COMPARTIR LA CONTRASE&Ntilde;A DE LA CUENTA TCS
    GO, USTED COMO USUARIO DE LA CUENTA TCS GO (I) ASUME LA RESPONSABILIDAD DE GARANTIZAR QUE QUIEN LA UTILICE OBSERVE
    LOS T&Eacute;RMINOS DE USO ESTABLECIDOS EN ESTE CONTRATO, Y (II) RECONOCE QUE USTED SER&Aacute; RESPONSABLE POR
    TODOS LOS ACTOS DE QUIEN HAGA USO DE SU CUENTA TCS GO, AS&Iacute; COMO DE QUIEN TENGA ACCESO A LA MISMA, A LOS
    DISPOSITIVOS Y/O A LOS DATOS RELACIONADOS CON LA FORMA DE PAGO.</p>
<p style="text-align:center;"><strong>________________________________________</strong></p>
<p style="text-align:center;"><strong>7. LICENCIA DE SOFTWARE</strong></p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:justify;">El Software y las funcionalidades pueden variar o diferir seg&uacute;n el Dispositivo
    utilizado. Por ejemplo, el Software puede estar integrado a un Dispositivo listo para TCS GO, o bien, se puede
    descargar este Software a un Dispositivo. De cualquier manera, TCS le otorga el Software bajo licencia gratuita y
    limitada al uso personal de conformidad con lo se&ntilde;alado en el presente Contrato y &uacute;nicamente con el
    fin de utilizar TCS GO con exclusi&oacute;n de cualquier otro fin. TCS NO GARANTIZA EL DESEMPE&Ntilde;O DEL
    SOFTWARE, NI SU COMPATIBILIDAD CON TCS GO.&nbsp;<span style="font-size:11px;line-height:107%;"><a href="#_msocom_1"
            id="_anchor_1" language="JavaScript" name="_msoanchor_1">[ID1]</a>&nbsp;</span><span
        style="font-size:11px;line-height:107%;"><a href="#_msocom_2" id="_anchor_2" language="JavaScript"
            name="_msoanchor_2">[GC2]</a>&nbsp;</span>Usted no puede copiar ni reproducir el Software, ni descompilar,
    realizar ingenier&iacute;a inversa, desarmar, modificar y/o crear cualquier otro sistema, programa u obra derivada
    basada en el Software o en una parte del Software. Todo uso no autorizado del Software queda estrictamente
    prohibido, por lo que en dado caso TCS se reserva el derecho de no proveerle a Usted el Software (ni sus
    actualizaciones) en cualquier momento y de bloquear el acceso a TCS GO a trav&eacute;s del Software en cualquier
    momento.</p>
<p style="text-align:justify;">Al utilizar TCS GO, Usted reconoce y acuerda (i) cumplir con los t&eacute;rminos de este
    Contrato y con las modificaciones que de tiempo en tiempo se realicen al mismo, y (ii) recibir las versiones
    actualizadas del Software y de terceros relacionados. Si no acepta estas condiciones, reconoce que no
    utilizar&aacute; TCS GO ni instalar&aacute; el Software.</p>
<p style="text-align:justify;">Si Usted ya es suscriptor de TCS GO y no est&aacute; de acuerdo con todos o algunos los
    t&eacute;rminos de este Contrato, por favor siga las instrucciones que se establecen en este Contrato para cancelar
    su Cuenta TCS GO.</p>
<p style="text-align:justify;">TCS no garantiza que el Software sea compatible con software de terceros ni que el
    funcionamiento de TCS GO y/o del Software no da&ntilde;ar&aacute; ni afectar&aacute; otro software, hardware o
    Dispositivo alguno.</p>
<p style="text-align:justify;">En respuesta a solicitudes de proveedores de contenidos o debido a limitaciones
    t&eacute;cnicas, o por cualquier otro motivo, a total y &uacute;nica discreci&oacute;n de TCS y sin aviso previo,
    usted acepta que algunos Programas no podr&aacute;n visualizarse en algunas ubicaciones geogr&aacute;ficas o no
    estar&aacute;n disponibles para verlos al instante a trav&eacute;s de TCS GO en algunos Dispositivos.</p>
<p style="text-align:center;"><strong>________________________________________</strong></p>
<p style="text-align:center;"><strong>8. ACCESO A SU CUENTA TCS GO; PROTECCI&Oacute;N DE LA IDENTIDAD</strong></p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:justify;">A efecto de facilitar el acceso a su Cuenta TCS GO y para administrar mejor TCS GO, TCS
    implementa tecnolog&iacute;a que le permite reconocerlo a Usted como titular de la Cuenta TCS GO y darle acceso
    directo a &eacute;sta sin solicitarle que vuelva a introducir ninguna contrase&ntilde;a u otra identificaci&oacute;n
    de Usuario al volver a ingresar a TCS GO. Es su responsabilidad actualizar y mantener la veracidad y la exactitud de
    la informaci&oacute;n personal que nos brinda respecto de su Cuenta TCS GO.</p>
<p style="text-align:justify;">Usted reconoce que es responsable tanto de proteger la confidencialidad de su Cuenta TCS
    GO y contrase&ntilde;a como de restringir el acceso a los Dispositivos mediante los cuales accede a su Cuenta TCS
    GO. Si revela su contrase&ntilde;a a cualquier persona o comparte su Cuenta TCS GO o Dispositivos con otras
    personas, Usted asume plena responsabilidad por sus actos. En caso de que Usted utilice dispositivos
    electr&oacute;nicos p&uacute;blicos o compartidos para acceder a TCS GO, Usted deber&aacute; cerrar sesi&oacute;n al
    finalizar cada visita. Si vende o devuelve un Dispositivo, Usted debe cerrar sesi&oacute;n y desactivar el
    dispositivo antes de hacerlo. De igual manera, en el caso de extrav&iacute;o o robo de su Dispositivo, Usted
    deber&aacute; reportarlo al centro de servicio al cliente (ver Secci&oacute;n 13 siguiente) para que dicho
    Dispositivo no sea objeto de un mal uso. Si no cierra sesi&oacute;n o no desactiva el Dispositivo, otros usuarios
    posteriores podr&aacute;n acceder a TCS GO a trav&eacute;s de su Cuenta TCS GO, y llegar a acceder a alguna
    informaci&oacute;n de su Cuenta TCS GO.</p>
<p style="text-align:justify;">Si descubre que fue v&iacute;ctima de robo de identidad y el delito se llev&oacute; a
    cabo a trav&eacute;s de una Cuenta TCS GO, debe notificarlo inmediatamente al centro de servicio al cliente (ver
    Secci&oacute;n 13 siguiente). Adem&aacute;s, es su responsabilidad denunciar el robo a los emisores de su tarjeta de
    cr&eacute;dito en caso de que &eacute;sta sea la Forma de Pago seleccionada, y tambi&eacute;n a las autoridades
    competentes de su pa&iacute;s. TCS se reserva el derecho de suspender cualquier Cuenta TCS GO en cualquier momento,
    con o sin aviso al Usuario, con el fin de protegerse y proteger a sus socios ante lo que considera que puede
    constituir una actividad fraudulenta.</p>
<p style="text-align:center;"><strong>________________________________________</strong></p>
<p style="text-align:center;"><strong>9. EXCLUSI&Oacute;N DE GARANT&Iacute;AS Y LIMITACIONES A LA
        RESPONSABILIDAD</strong></p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:justify;">TCS GO, INCLUIDO EL SITIO Y/O APP, LOS PROGRAMAS Y EL SOFTWARE O CUALQUIER OTRA
    CARACTER&Iacute;STICA O FUNCIONALIDAD DE TCS GO SE OFRECEN &quot;EN EL ESTADO EN QUE SE ENCUENTRAN&quot;, CON TODAS
    SUS FALLAS Y SIN GARANT&Iacute;AS DE NING&Uacute;N TIPO. TCS Y SUS RESPECTIVOS LICENCIANTES NO EMITEN DECLARACIONES
    NI GARANT&Iacute;AS DE NING&Uacute;N TIPO RESPECTO DE TCS GO, LA SUSCRIPCI&Oacute;N, SU CALIDAD, EL SITIO Y/O APP,
    LOS PROGRAMAS Y/O EL SOFTWARE. TCS NO MANIFIESTA, DECLARA NI GARANTIZA QUE EL USUARIO PODR&Aacute; UTILIZAR TCS GO
    SIN INTERRUPCIONES O SIN ERRORES, Y USTED ACUERDA QUE TCS PODR&Aacute; ELIMINAR O MODIFICAR DE CUALQUIER MANERA LOS
    ASPECTOS DE TCS GO, INCLUIDA LA FUNCIONALIDAD, SIN AVISO NI INDEMNIZACI&Oacute;N ALGUNA. TCS MANIFIESTA EXPRESAMENTE
    QUE NO ASUME RESPONSABILIDAD ALGUNA POR EL USO DE LAS APLICACIONES, LOS DISPOSITIVOS Y EL SOFTWARE (INCLUYENDO LA
    COMPATIBILIDAD CON TCS GO). Sin perjuicio de lo anterior, TCS y sus licenciantes no asumen responsabilidad alguna
    respecto de lo siguiente: (i) errores u omisiones en el contenido provisto por TCS GO, en el Sitio y/o App o en el
    Software; (ii) recomendaciones o asesoramiento del centro de servicio al cliente, (iii) cualquier falla o
    interrupci&oacute;n en la disponibilidad de TCS GO, el Sitio y/o App o el Software; (iv) la provisi&oacute;n o
    transmisi&oacute;n de cualquier Programa o Software, y/o (v) cualquier p&eacute;rdida o da&ntilde;o y/o perjuicio
    que surja del uso del Software, los Programas o contenidos del Sitio y/o App, incluidos los da&ntilde;os o
    p&eacute;rdidas causados por la descarga del Software, la descarga o uso de cualquier otro software, incluida la
    herramienta de escritorio remoto que ofrece TCS GO a los Usuarios, los discos &oacute;pticos o cualquier otra
    conducta de los Usuarios de TCS GO, el Sitio y/o App o el Software. EN LA MEDIDA EN QUE LO PERMITA LA
    LEGISLACI&Oacute;N APLICABLE, TCS Y SUS LICENCIANTES EXCLUYEN CUALQUIER DECLARACI&Oacute;N O GARANT&Iacute;A,
    INCLUYENDO SIN LIMITAR, LAS GARANT&Iacute;AS DE COMERCIABILIDAD, DE CALIDAD, DE APTITUD PARA CUALQUIER FIN EN
    PARTICULAR O DE RESPETO DE LOS DERECHOS DE AUTOR. TCS tampoco declara ni garantiza que la informaci&oacute;n a la
    que se accede a trav&eacute;s del Sitio y/o App sea precisa, completa o actualizada, ni declara ni garantiza que el
    uso que se haga de TCS GO est&eacute; libre de interrupci&oacute;n, p&eacute;rdida, corrupci&oacute;n, ataque,
    virus, interferencias, pirater&iacute;a inform&aacute;tica o cualquier otra intrusi&oacute;n contra la seguridad y
    no asume ninguna responsabilidad en relaci&oacute;n con estos hechos. La informaci&oacute;n que TCS provea en forma
    oral o escrita no generar&aacute; ninguna garant&iacute;a ni declaraci&oacute;n que pueda generar responsabilidad
    para &eacute;ste o para sus partes vinculadas.</p>
<p style="text-align:justify;">En ning&uacute;n caso tcs, sus controladoras, filiales o subsidiarias y sus respectivos
    accionistas, directores, ejecutivos, empleados o licenciantes, ser&aacute;n responsables (en forma individual o en
    su conjunto) ante usted por los da&ntilde;os personales o por cualquier da&ntilde;o especial, incidental, indirecto
    o cualquier da&ntilde;o que surgiera de la p&eacute;rdida de uso, datos o ganancias, lucro cesante o cualquier otro
    da&ntilde;o o p&eacute;rdida de naturaleza comercial, ya sea que se hubiera advertido o no la posibilidad del
    da&ntilde;o y cualquier responsabilidad que pudiera surgir con el uso de TCS GO, los programas, el sitio y/o app y
    el software, incluidos los discos &oacute;pticos, las caracter&iacute;sticas o funcionalidades de TCS GO. la
    responsabilidad total de tcs por cualquier da&ntilde;o o p&eacute;rdida provocada por el uso o la imposibilidad de
    utilizar TCS GO (incluidos el sitio y/o app, los programas y el software) no podr&aacute; exceder en ning&uacute;n
    caso el monto de la cuota mensual de suscripci&oacute;n mensual correspondiente. Las limitaciones antedichas se
    aplicar&aacute;n incluso si el resarcimiento previsto no cumple su &uacute;ltima finalidad. si alguna autoridad
    competente declarara la invalidez de alguna disposici&oacute;n de esta cl&aacute;usula o de cualquier otra
    cl&aacute;usula de este contrato, s&oacute;lo las disposiciones declaradas inv&aacute;lidas dejar&aacute;n de ser
    aplicables bajo el presente contrato.</p>
<p style="text-align:justify;">Tcs no otorga ninguna declaraci&oacute;n o garant&iacute;a respecto de los dispositivos
    ni la compatibilidad o configuraci&oacute;n de los dispositivos con TCS GO, as&iacute; como respecto de su
    conexi&oacute;n a internet. Los distintos contratos de licencia para usuario final de software de terceros que Usted
    haya acordado a fin de acceder a TCS GO pueden contener exclusiones adicionales o limitaciones a la responsabilidad.
</p>
<p style="text-align:center;"><strong>________________________________________</strong></p>
<p style="text-align:center;"><strong>10. PROPIEDAD INTELECTUAL</strong></p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:justify;"><strong>1.</strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<strong>Derechos de
        propiedad intelectual sobre los Programas y Software.</strong> Todos los Programas y el Software son propiedad
    de TCS y/o de sus respectivos licenciantes, y est&aacute;n protegidos por las correspondientes leyes de derechos de
    autor y/u otras leyes o tratados internacionales, seg&uacute;n corresponda y resulte aplicable. La
    compilaci&oacute;n de los Programas y cualquier Software u otro material provisto por TCS en el Sitio y/o App o
    interfaz de Usuario o en relaci&oacute;n con TCS GO o el Sitio y/o App son de exclusiva propiedad de TCS y/o de sus
    respectivos licenciantes y est&aacute;n protegidos por las leyes de derechos de autor y/u otras leyes aplicables en
    los territorios en los que funciona TCS GO y por las disposiciones de los tratados internacionales. Usted no
    podr&aacute; reproducir y/o utilizar, total o parcialmente, por cualquier forma o medio, los Programas, su contenido
    y/o informaci&oacute;n para fines distintos a los autorizados en este documento. Usted acuerda cumplir con las
    limitaciones establecidas en el presente Contrato y acuerda no descompilar, realizar ingenier&iacute;a inversa o
    desarmar el Software o cualquier otro producto o proceso a los que se acceda a trav&eacute;s de TCS GO, no insertar
    c&oacute;digos o productos o manipular el contenido de TCS GO de ninguna manera y no utilizar ning&uacute;n
    m&eacute;todo de an&aacute;lisis, extracci&oacute;n u obtenci&oacute;n de datos. TCS se reserva el derecho de dar de
    baja TCS GO y/o la Suscripci&oacute;n Mensual del Usuario si considera, a su &uacute;nica y total discreci&oacute;n,
    que Usted o cualquier otro Usuario de su Cuenta TCS GO infringi&oacute; o se encuentra infringiendo las
    restricciones del Software, derechos de propiedad intelectual de terceros o de TCS. El uso de TCS GO, incluidos los
    Programas, son exclusivamente para uso personal y no comercial. Para denunciar la existencia de cualquier contenido
    de TCS GO que infrinja derechos de autor, incluidos los contenidos del Sitio y/o App y el Software, por favor
    comun&iacute;quese con servicio al cliente (ver Secci&oacute;n 13 siguiente).</p>
<p style="text-align:justify;"><strong>2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Derechos de propiedad
        intelectual sobre TCS GO</strong>. TCS GO y el logotipo de TCS GO son marcas registradas y obras protegidas por
    el derecho de autor, por lo que solamente pueden ser utilizadas con autorizaci&oacute;n previa y por escrito de TCS.
    El Sitio y/o App y las interfaces de Usuario, incluidas, entre otras, sus gr&aacute;ficos, logos, t&iacute;tulos,
    iconos de botones, guiones y nombres de servicio, son marcas de TCS y/o de sus titulares y TCS se encuentra
    debidamente autorizado para utilizarlas. Otras marcas que aparecen en el Sitio y/o App y las interfaces de Usuario
    son de propiedad de sus respectivos titulares, que pueden o no estar vinculados o patrocinados por TCS.</p>
<p style="text-align:center;"><strong>________________________________________</strong></p>
<p style="text-align:center;"><strong>11. CONTENIDO DE TCS GO</strong></p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:justify;">TCS ofrece a Usted el contenido de los Programas entre los que se encuentran
    pel&iacute;culas, programas de televisi&oacute;n y cualquier otro entretenimiento filmado, por lo que se reserva el
    derecho de mostrar y promover el entretenimiento filmado u otra informaci&oacute;n a trav&eacute;s de TCS GO,
    incluido el Sitio y/o App e interfaces de Usuario, de cualquier forma, que TCS considere pertinente a su entera
    discreci&oacute;n. Toda opini&oacute;n, asesoramiento, declaraci&oacute;n, servicio, oferta u otra
    informaci&oacute;n que forme parte del contenido expresado o provisto por terceros a trav&eacute;s de TCS GO
    corresponden a sus respectivos autores o productores y no reflejan la opini&oacute;n, el asesoramiento, la
    declaraci&oacute;n, el servicio, la oferta u otra informaci&oacute;n de TCS o de sus accionistas, directores,
    ejecutivos, empleados o licenciantes. TCS y sus accionistas, directores, ejecutivos, empleados o licenciantes no
    ser&aacute;n responsables en ning&uacute;n caso por p&eacute;rdidas o da&ntilde;os provocados por confiar en la
    informaci&oacute;n obtenida a trav&eacute;s de TCS GO. Es responsabilidad del Usuario evaluar la informaci&oacute;n,
    opini&oacute;n, consejo u otros contenidos disponibles a trav&eacute;s de TCS GO y/o el Sitio y/o App.</p>
<p style="text-align:center;"><strong>________________________________________</strong></p>
<p style="text-align:center;"><strong>12. DERECHO DE TERMINACI&Oacute;N Y RESCISI&Oacute;N</strong></p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:justify;">TCS se reserva el derecho de terminar o rescindir el presente Contrato o de restringir el
    uso que Usted haga de TCS GO, sin aviso, con o sin causa, y sin responsabilidad alguna para ninguna de las partes.
</p>
<p style="text-align:center;"><strong>________________________________________</strong></p>
<p style="text-align:center;"><strong>13. CENTRO DE SERVICIO AL CLIENTE</strong></p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:justify;"><span >Si Usted necesita asistencia con su Cuenta TCS GO, puede
        contactar al correo <a href="mailto:soporte.tcsgo@tcs246.com">soporte.tcsgo@tcs246.com</a> o por
        las cuentas de Meta de TCS GO.</span></p>
<p style="text-align:justify;"><span >Para dar respuesta a tus dudas, te sugerimos consultar
        las FAQs publicadas en el Sitio.</span></p>
<p style="text-align:center;"><strong>________________________________________</strong></p>
<p style="text-align:center;"><strong>14. CONDUCTA AL ACCEDER Y USAR TCS GO</strong></p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:justify;">Al acceder a TCS GO, incluido el Sitio y/o App y las interfaces de Usuario, Usted acuerda
    utilizar TCS GO, incluidas todas sus caracter&iacute;sticas y funcionalidades, el Sitio y/o App y las interfaces de
    Usuario y todo el contenido y el Software de conformidad con todas las leyes, normas y reglamentaciones vigentes,
    incluidas las limitaciones de presentaci&oacute;n en p&uacute;blico o cualquier otra restricci&oacute;n al uso de
    TCS GO o sus contenidos o Programas. Asimismo, Usted se compromete a no subir, publicar, enviar por email o
    transmitir de cualquier otra forma ning&uacute;n material que contenga virus de software o cualquier otro
    c&oacute;digo, archivo o programa de computaci&oacute;n dise&ntilde;ado para interrumpir, destruir o limitar la
    funcionalidad del software de computaci&oacute;n, hardware o equipos de telecomunicaciones asociados con TCS GO.
    Usted tambi&eacute;n se obliga a no interferir con los servidores o las redes conectadas a cualquier parte de TCS GO
    ni infringir cualquier procedimiento, pol&iacute;tica o reglamentaci&oacute;n de redes conectadas a TCS GO. Usted
    tambi&eacute;n se obliga a no hacerse pasar por otra persona al utilizar TCS GO, a no comportarse en forma vulgar u
    ofensiva al utilizar TCS GO, y a no utilizar TCS GO con un fin il&iacute;cito.</p>
<p style="text-align:center;"><strong>________________________________________</strong></p>
<p style="text-align:center;"><strong>15. RESPONSABILIDAD Y LIMITACIONES DE USO</strong></p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:justify;">Usted est&aacute; obligado a utilizar TCS GO y el Software conforme a lo se&ntilde;alado
    en el presente Contrato, as&iacute; como a cumplir con la legislaci&oacute;n aplicable en materia de propiedad
    intelectual e industrial. Asimismo, Usted reconoce y acepta expresamente que el uso de TCS GO no est&aacute;
    permitido para fines comerciales y cualesquiera otros fines ajenos de los descritos en el presente documento.</p>
<p style="text-align:justify;">Usted asume bajo su exclusiva responsabilidad las consecuencias, da&ntilde;os o acciones
    que pudieran derivarse del uso indebido de TCS GO, del Software y/o Programas.</p>
<p style="text-align:justify;">Usted debe tener 18 a&ntilde;os o m&aacute;s para ser titular de la Cuenta TCS GO. En
    algunas jurisdicciones, la mayor&iacute;a de edad puede ser al cumplir m&aacute;s de 18 a&ntilde;os, en cuyo caso
    deber&aacute; tener la edad necesaria, en su pa&iacute;s de residencia, para ser titular de la Cuenta TCS GO. Si
    bien los menores de 18 a&ntilde;os pueden utilizar TCS GO, s&oacute;lo pueden hacerlo con la participaci&oacute;n,
    supervisi&oacute;n y aprobaci&oacute;n de un padre o tutor, a trav&eacute;s de la Cuenta TCS GO de esa persona y
    sujeto al presente Contrato. TCS GO y los contenidos a los que se accede a trav&eacute;s de TCS GO son para uso
    personal y no comercial. Salvo por la licencia limitada antes se&ntilde;alada, TCS y/o sus licenciantes no le
    otorgan derecho o t&iacute;tulo alguno sobre los Programas, su contenido e informaci&oacute;n, el Sitio y/o App y/o
    el Software.</p>
<p style="text-align:justify;">Usted reconoce que no podr&aacute; descargar, modificar, copiar, distribuir, transmitir,
    ejecutar, reproducir, duplicar, publicar, otorgar licencias, crear obras derivadas de los Programas y dem&aacute;s
    contenidos de TCS GO, u ofrecer en venta la informaci&oacute;n contenida en u obtenida a trav&eacute;s de
    &eacute;stos sin consentimiento previo por escrito de TCS. Est&aacute; prohibido evitar, eliminar, modificar,
    desactivar, interferir con o burlar cualquier medida tecnol&oacute;gica de protecci&oacute;n y/o cualquier otra
    relacionada con los Programas y dem&aacute;s contenido de TCS GO. Usted tiene prohibido enmascarar o utilizar
    t&eacute;cnicas de enmascaramiento (framing) para ocultar una marca, un logotipo u otra informaci&oacute;n protegida
    por leyes de propiedad intelectual (tales como im&aacute;genes, textos, diagramas de p&aacute;ginas o formas) de TCS
    o sus licenciantes sin su consentimiento expreso por escrito. Usted tiene prohibido comprar t&eacute;rminos de
    b&uacute;squeda o utilizar metaetiquetas o cualquier otro &quot;texto oculto&quot; utilizando el nombre o las marcas
    de TCS sin consentimiento expreso de TCS. Todo uso no autorizado de TCS GO o sus contenidos dejar&aacute; sin efecto
    la licencia limitada otorgada al Usuario y dar&aacute; lugar a la cancelaci&oacute;n definitiva de su Cuenta TCS GO.
</p>
<p style="text-align:center;"><strong>________________________________________</strong></p>
<p style="text-align:center;"><strong>16. RECLAMOS POR FALTA DE CUMPLIMIENTO DE DERECHOS DE AUTOR</strong></p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:justify;">TCS tiene la pol&iacute;tica de respetar los derechos de propiedad intelectual de
    terceros. TCS no tolerar&aacute; la reproducci&oacute;n o distribuci&oacute;n no autorizadas de los Programas o
    cualquier otro contenido protegido por derechos de autor. Si Usted considera que su obra se ha reproducido o
    distribuido de forma tal que constituye una falta de observancia de los derechos de autor o sabe que existe material
    disponible a trav&eacute;s de TCS GO que no respeta los derechos de autor, por favor comun&iacute;quese al centro de
    servicio al cliente.</p>
<p style="text-align:center;"><strong>________________________________________</strong></p>
<p style="text-align:center;"><strong>17. LEGISLACI&Oacute;N APLICABLE Y JURISDICCI&Oacute;N</strong></p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:justify;">Por lo que se refiere a TCS GO y a la licencia del Software, el presente Contrato se
    regir&aacute; e interpretar&aacute; de acuerdo con las leyes aplicables en la Rep&uacute;blica de El Salvador, y las
    partes del presente Contrato convienen expresa e irrevocablemente en someterse a la jurisdicci&oacute;n de los
    tribunales competentes de la Ciudad de San Salvador, departamento de San Salvador, respecto a cualquier demanda,
    acci&oacute;n o procedimiento y renuncian expresa e irrevocablemente a cualquier otra jurisdicci&oacute;n que les
    pudiera corresponder por raz&oacute;n de sus domicilios presentes o futuros o por alguna otra causa.</p>
<p style="text-align:justify;">En caso de que Usted desee iniciar cualquier demanda, acci&oacute;n o procedimiento de
    cualquier naturaleza en contra de TCS GO, deber&aacute; notificarlo al Departamento Legal de TCS por escrito
    (incluyendo cualquier copia o acuse de la constancia del procedimiento iniciado en contra de TCS) con acuse de
    recibo al domicilio ubicado en Alameda Dr. Manuel Enrique Araujo, Km 6, carretera a Santa tecla, Edificio de TCS,
    San Salvador, Rep&uacute;blica de El Salvador, Centro Am&eacute;rica.</p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:center;"><strong>________________________________________</strong></p>
<p style="text-align:center;"><strong>18. AVISO DE PRIVACIDAD</strong></p>
<p style="text-align:justify;">&nbsp;</p>
<p style="text-align:justify;">TCS tiene la convicci&oacute;n de proteger los datos personales que Usted proporcione y
    es el responsable de su tratamiento cuando se recaben a trav&eacute;s del Sitio y/o App. Para m&aacute;s
    informaci&oacute;n sobre el tratamiento de los datos personales y los derechos que Usted puede hacer valer para
    acceder, rectificar, cancelar y oponerse al tratamiento de estos, consulte el Sitio y/o App en donde podr&aacute;
    encontrar el Aviso de Privacidad integral.</p>
<p style="text-align:justify;">Al usar TCS GO y el Software, Usted manifiesta que est&aacute; de acuerdo con las
    pol&iacute;ticas y pr&aacute;cticas expresadas en el Aviso de Privacidad ubicado en el Sitio y/o App.</p>
<p style="text-align:justify;">&nbsp;</p>


</div>
  `;

  return (
    <div>
      <HTMLRenderer htmlString={htmlContent} className="custom-html-class" />
      {/* hola */}
      {}
    </div>
  );
};

export { MarkdownComponent, HTMLRenderer };
export default Terminos;
