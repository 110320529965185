import React from 'react';

import MarkdownComponent from '../../components/MarkdownComponent/MarkdownComponent';

import styles from './About.module.scss';

const About = () => {
  const markdownPage = `
  
TCS Go es la aplicación de streaming de Telecorporación Salvadoreña (TCS). Disfruta de tu programación favorita de Canal 2, Canal 4, Canal 6 y TCS Plus, así como programas on demand, disponibles según tu país.


`;

  return <MarkdownComponent className={styles.about} markdownString={markdownPage} />;
};

export default About;
