// src/components/AdComponent.tsx
import React, { useEffect } from 'react';

interface AdComponentProps {
  adId: string; // ID del div del anuncio
}

const AdComponent: React.FC<AdComponentProps> = ({ adId }) => {
    useEffect(() => {
        window.googletag = window.googletag || { cmd: [] };
        window.googletag.cmd.push(() => {
            window.googletag.display(adId);
        });
    }, [adId]);

    return <div id={adId} style={{ width: 'auto', height: 'auto', marginTop:adId !== 'top3_ad' ? '12px' : '0px', textAlign:'center' }} />;
};

export default AdComponent;
