import { ACCESS_MODEL, OTT_GLOBAL_PLAYER_ID } from '../constants';
import type { AccessModel, Config } from '../../types/config';
import type { Settings } from '../../types/settings';
import type { LanguageDefinition } from '../../types/i18n';

import { createStore } from './utils';

type ConfigState = {
  loaded: boolean;
  config: Config;
  accessModel: AccessModel;
  settings: Settings;
  integrationType: string | null;
  supportedLanguages: LanguageDefinition[];
};

export const useConfigStore = createStore<ConfigState>('ConfigStore', () => ({
  loaded: false,
  config: {
    id: '',
    siteName: 'TCS Go Web',
    description: 'TCS Go es la aplicación de streaming de Telecorporación Salvadoreña (TCS). Disfruta de tu programación favorita de Canal 2, Canal 4, Canal 6 y TCS Plus, así como programas on demand, disponibles según tu país.',
    player: '',
    siteId: '',
    assets: {},
    content: [],
    menu: [],
    integrations: {
      cleeng: {
        useSandbox: true,
      },
      jwp: {
        clientId: null,
        assetId: null,
        useSandbox: true,
      },
    },
    styling: {},
  },
  settings: {
    additionalAllowedConfigSources: [],
    playerId: OTT_GLOBAL_PLAYER_ID,
  },
  supportedLanguages: [],
  accessModel: ACCESS_MODEL.AVOD,
  integrationType: null,
}));
